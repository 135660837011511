<template>
  <b-row
    v-if="ready"
  >
    <b-col lg="10">
        <b-form-input
          placeholder="Nom de votre nouveau dossier"
          size="md"
          v-model="title"
        />
    </b-col>
    <b-col lg="2">
      <b-button
        :disabled="title.length == 0"
        size="md"
        variant="primary"
        class="btn-icon"
        @click="createdirectory"
        style="width:100%"
      >
        Créer un dossier
      </b-button>
    </b-col>
    <b-col
      v-for="dossier in dossiers"
      :key="dossier.id"
      sm="12"
      md="4"
      lg="4"
    >
      <b-card class="text-left mt-2" style="border: 0.5px solid grey; -moz-box-shadow: 3px 3px 5px 6px #ccc; -webkit-box-shadow: 3px 3px 5px 6px #ccc; box-shadow: 3px 3px 5px 6px #ccc;">
        <h5 class="text-center">#{{ dossier.id }}. {{ dossier.title }}</h5>
        <hr>
        <b-row>
          <b-col lg="9" md="9" sm="12">
            <b-button
              style="width:100%"
              variant="primary"
              class="btn-icon mb-1"
              @click="rentreDansLeDossier(dossier.id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <b-button
              style="width:100%"
              variant="danger"
              class="btn-icon mb-1"
              @click="confirmDelete(dossier.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import router from '@/router'
import axios from '@axios'
import {
  BCard, BCardText, BRow, BCol, BButton, BImg, BSidebar, VBToggle, BCarousel, BCarouselSlide, BEmbed, BAvatar, BAvatarGroup, BFormGroup, BFormInput, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BImg,
    BSidebar,
    /* eslint-disable vue/no-unused-components */
    VBToggle,
    BCarousel,
    BCarouselSlide,
    BEmbed,
    BAvatar,
    BAvatarGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      dossiers: [],
      ready: false,
      file: null,
      title: '',
    }
  },
  methods: {
    rentreDansLeDossier(id) {
      this.$router.push({ name: 'fichier', params: { id: id } })
    },
    createdirectory() {
      axios
        .post('https://app2.oscar-black.com/api/dossier', { title: this.title })
        .then(response => {
          this.dossiers = response.data
          this.$swal({
            icon: 'success',
            title: 'TOP !',
            text: 'Votre dossier a bien été créé.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Oops, impossible de créer ce dossier !',
            text: error.response.data,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Êtes-vous sûr ?',
        text: 'Vous allez supprimer le dossier et tout son contenu, il sera alors impossible de revenir en arrière...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui, supprimer ce dossier',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const req = {
            _id: id,
          }
          axios
            .delete('https://app2.oscar-black.com/api/dossier', { data: req })
            .then(response => {
              this.dossiers = response.data
              this.$swal({
                icon: 'success',
                title: 'Supprimé !',
                text: 'Votre dossier a bien été supprimé.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              this.$swal({
                icon: 'error',
                title: 'Oops, dossier pas supprimé !',
                text: error.response.data,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'Annulé',
            text: 'Votre dossier est en sécurité :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

  },
  created() {
    axios
      .get('https://app2.oscar-black.com/api/dossier')
      .then(response => {
        this.dossiers = response.data
        setTimeout(() => { this.ready = true }, 500)
      })
      .catch(() => {})
  },
  watch: {
    file(newvalue) {
      console.log(newvalue)
      if (newvalue !== null) {
        console.log('hey')
        for(var i = 0; i < newvalue.length; i++) {
          this.title = newvalue[i].name
          if (newvalue[i].type.includes('image')) {
            const reader = new FileReader()
            reader.readAsDataURL(newvalue[i])
            const vm = this
            var fil = newvalue[i]
            reader.onload = e => {
              const image = new Image()
              image.src = e.target.result
              image.onload = function () {
                const { height, width } = this
                if (height === width || width / height === 0.5625) {
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Le visuel est au bon format :)',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  const formData = new FormData()

                  formData.append('file', fil)
                  formData.append('title', vm.title)

                  axios
                    .post('https://app2.oscar-black.com/api/dossier', formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    })
                    .then(response => {
                      vm.file = null;
                      vm.title = null;
                      vm.dossiers = response.data;
                      vm.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Fichier ajouté !',
                          icon: 'CheckIcon',
                          variant: 'success',
                        },
                      })
                    })
                    .catch(() => {
                      vm.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Erreur',
                          icon: 'CrossIcon',
                          variant: 'danger',
                        },
                      })
                    })
                } else {
                  vm.title = null
                  vm.file = null
                  vm.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur, le visuel doit être au format carré ou au format 9:16 (1080x1920)',
                      icon: 'CrossIcon',
                      variant: 'danger',
                    },
                  })
                }
                return true
              }
            }
          }
          else {
            const formData = new FormData()

            formData.append('file', this.file)
            formData.append('title', this.title)

            axios
              .post('https://app2.oscar-black.com/api/drive', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then(response => {
                this.dossiers = response.data;
                this.file = null;
                this.title = null;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Fichier ajouté !',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'CrossIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>
